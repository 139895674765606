
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EnterpriseNoContent extends Vue {
  private goToEnterprise() {
    this.$router.push({ name: 'enterprise-create' });
  }

  private created() {
    document.addEventListener('DOMContentLoaded', () => {
      window.setTimeout(() => {
        const el = document.querySelector('svg') as SVGSVGElement;
        el.classList.add('animated');
      }, 1000);
    });
  }
}
