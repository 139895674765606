
import { Component, Vue } from 'vue-property-decorator';
import { Enterprise } from '../../entities/enterprise.entity';
import enterpriseService from '../../services/enterprise.service';
import EnterpriseNoContent from './enterprise-no-content.component.vue';

@Component({
  components: {
    EnterpriseNoContent,
  },
})
export default class EnterpriseListComponent extends Vue {
  private enterprises: Enterprise[] = [];

  private headers: any = [
    { text: 'Nome', value: 'name', width: '50%' },
    {
      text: 'Qtd. Unidades',
      value: 'total_units',
    },
    {
      text: 'Status',
      value: 'activeText',
    },
    {
      text: 'Ações',
      align: 'center',
      value: 'actions',
    },
  ];

  private filter = {
    active: 1,
  };

  private situations: any = [
    { text: 'Ativo', value: 1 },
    {
      text: 'Inativo',
      value: 0,
    },
  ];

  private goToFormEdit(enterprise: Enterprise) {
    this.$router.push({
      name: 'enterprise-edit',
      params: {
        id: enterprise.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'enterprise-create',
    });
  }

  private async getEnterprises() {
    this.$loader.open();
    const enterprises = await enterpriseService.getAllEnterprises({ params: this.filter });
    this.enterprises = enterprises.map((enterprise: Enterprise) => new Enterprise(enterprise));
    this.$loader.close();
  }

  private async created() {
    this.getEnterprises();
  }
}
